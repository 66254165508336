import React from 'react'
import './SportsClub.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from "react";

function SportsClub() {
    const [SportsClub, setSportsClub] = useState(true);
    var Sports = 1;
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])

    return (
        <div className='marginTopFromNavbar'>
            <div>

                {/* <div className="events_component_1">
                <div className="events_1">
                    <h1>SPORTS FACILITY</h1>
                </div>
                <div className="events_2">Home - Bookings - Sports Facility</div>
            </div> */}
                {/* <div className="header_new">
                    <div className="text-container">
                    <div className="text">SPORTS FACILITY</div>
               <    div className="text2">Home - Bookings - Sports Facility</div>
                  </div>
             </div> */}
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", margin: "auto"}}>


                    <div className='intro-text-header'>Work Hard, Play Harder</div>
                    <hr />
                    <div className='intro-text'>Sports facility booking involves reserving a space or venue for a specific sports-related activity or event, such as a game, practice, tournament, or training session. The process typically includes selecting a date, time, and location, as well as determining the necessary equipment and resources needed for the activity. Depending on the type of facility, it may also be necessary to negotiate pricing and obtain any required permits or insurance. Timely and effective sports facility booking is essential to ensure a smooth and successful event, while also providing a safe and enjoyable environment for all participants.</div>
                </div>



                <div className='Card_Events'>
                    <div class="sports_club">
                        <button div className="sports_club_active" onClick={() => setSportsClub(true)}>Sports Facility (4)</button>
                        {/* <button onClick={()=>setSportsClub(false)}>My Sports Club</button> */}
                    </div>
                    {SportsClub == 1 ?
                        <div>
                            <div className="FacilityEvent_main">
                                <div className='FacilityEvent'>
                                    <img src="/images/HockeyNew.jpg" />
                                    <div style={{ padding: "15px" }}>
                                        <div >
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <div className='SportsEvents' style={{ fontSize: "2.8rem" }}>Sports Facility 1</div>
                                                <div className='sports_availability'>10 Seats Available</div>
                                            </div>
                                            <div >

                                                <div className='FacilityEvent_desc'>Celebrate WITH FREINDS AND FAMILY</div>
                                            </div>
                                        </div>
                                        <div className="FacilityEvent_info">
                                            <div className=''>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                        <img src="/assets/icons/briefcase.svg" style={{ width: "16px" }} alt="" />
                                                    </div>
                                                    <div className='FacilityEvent_timings'>
                                                        <div>Business Hrs</div>
                                                        <div class="events-info-timing">Mon-Fri 10 am - 8 pm</div>
                                                        <div class="events-info-timing">Sat-Sun Closed</div>
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                        <img src="/assets/icons/fee-icon.svg" style={{ width: "16px" }} alt="" />
                                                    </div>
                                                    <div className='FaciltyEvent_booking_hrs'>
                                                        <div>Booking Hrs</div>
                                                        <div class="events-info-timing">4 Hrs/Booking</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=''>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                        <img src="/assets/icons/fee-icon.svg" style={{ width: "16px" }} alt="" />
                                                    </div>
                                                    <div className='FacilityEvent_fees'>

                                                        <div>FEES</div>
                                                        <div class="events-info-timing">0$ for members</div>
                                                        <div class="events-info-timing">50$/tax for non members</div>
                                                    </div>
                                                </div>

                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                        <img src="/assets/icons/pax.svg" style={{ width: "16px" }} alt="" />
                                                    </div>
                                                    <div className='FaciltyEvent_booking_hrs'>
                                                        <div>PAX</div>
                                                        <div class="events-info-timing">20 PAX Booking Done</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Link to='/SportsFacilityDetails'><button className='facilityEvent_book'>Book Now</button></Link>

                                    </div>



                                </div>

                                <div className='FacilityEvent'>
                                    <img src="/images/HockeyNew.jpg" />
                                    <div style={{ padding: "15px" }}>
                                        <div >
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <div className='SportsEvents' style={{ fontSize: "2.8rem" }}>Sports Facility 2</div>
                                                <div className='sports_availability'>10 Seats Available</div>
                                            </div>
                                            <div >

                                                <div className='FacilityEvent_desc'>Celebrate WITH FREINDS AND FAMILY</div>
                                            </div>
                                        </div>
                                        <div className="FacilityEvent_info">
                                            <div className=''>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                        <img src="/assets/icons/briefcase.svg" style={{ width: "16px" }} alt="" />
                                                    </div>
                                                    <div className='FacilityEvent_timings'>
                                                        <div>Business Hrs</div>
                                                        <div class="events-info-timing">Mon-Fri 10 am - 8 pm</div>
                                                        <div class="events-info-timing">Sat-Sun Closed</div>
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                        <img src="/assets/icons/fee-icon.svg" style={{ width: "16px" }} alt="" />
                                                    </div>
                                                    <div className='FaciltyEvent_booking_hrs'>
                                                        <div>Booking Hrs</div>
                                                        <div class="events-info-timing">4 Hrs/Booking</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=''>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                        <img src="/assets/icons/fee-icon.svg" style={{ width: "16px" }} alt="" />
                                                    </div>
                                                    <div className='FacilityEvent_fees'>

                                                        <div>FEES</div>
                                                        <div class="events-info-timing">0$ for members</div>
                                                        <div class="events-info-timing">50$/tax for non members</div>
                                                    </div>
                                                </div>

                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                        <img src="/assets/icons/pax.svg" style={{ width: "16px" }} alt="" />
                                                    </div>
                                                    <div className='FaciltyEvent_booking_hrs'>
                                                        <div>PAX</div>
                                                        <div class="events-info-timing">20 PAX Booking Done</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Link to='/SportsFacilityDetails'><button className='facilityEvent_book'>Book Now</button></Link>

                                    </div>

                                </div>

                            </div>

                            <div className="FacilityEvent_main">
                                <div className='FacilityEvent'>
                                    <img src="/images/HockeyNew.jpg" />
                                    <div style={{ padding: "15px" }}>
                                        <div >
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <div className='SportsEvents' style={{ fontSize: "2.8rem" }}>Sports Facility 3</div>
                                                <div className='sports_availability'>10 Seats Available</div>
                                            </div>
                                            <div >

                                                <div className='FacilityEvent_desc'>Celebrate WITH FREINDS AND FAMILY</div>
                                            </div>
                                        </div>
                                        <div className="FacilityEvent_info">
                                            <div className=''>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                        <img src="/assets/icons/briefcase.svg" style={{ width: "16px" }} alt="" />
                                                    </div>
                                                    <div className='FacilityEvent_timings'>
                                                        <div>Business Hrs</div>
                                                        <div class="events-info-timing">Mon-Fri 10 am - 8 pm</div>
                                                        <div class="events-info-timing">Sat-Sun Closed</div>
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                        <img src="/assets/icons/fee-icon.svg" style={{ width: "16px" }} alt="" />
                                                    </div>
                                                    <div className='FaciltyEvent_booking_hrs'>
                                                        <div>Booking Hrs</div>
                                                        <div class="events-info-timing">4 Hrs/Booking</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=''>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                        <img src="/assets/icons/fee-icon.svg" style={{ width: "16px" }} alt="" />
                                                    </div>
                                                    <div className='FacilityEvent_fees'>

                                                        <div>FEES</div>
                                                        <div class="events-info-timing">0$ for members</div>
                                                        <div class="events-info-timing">50$/tax for non members</div>
                                                    </div>
                                                </div>

                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                        <img src="/assets/icons/pax.svg" style={{ width: "16px" }} alt="" />
                                                    </div>
                                                    <div className='FaciltyEvent_booking_hrs'>
                                                        <div>PAX</div>
                                                        <div class="events-info-timing">20 PAX Booking Done</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Link to='/SportsFacilityDetails'><button className='facilityEvent_book'>Book Now</button></Link>

                                    </div>


                                </div>

                                <div className='FacilityEvent'>
                                    <img src="/images/HockeyNew.jpg" />
                                    <div style={{ padding: "15px" }}>
                                        <div >
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <div className='SportsEvents' style={{ fontSize: "2.8rem" }}>Sports Facility 4</div>
                                                <div className='sports_availability'>10 Seats Available</div>
                                            </div>
                                            <div >

                                                <div className='FacilityEvent_desc'>Celebrate WITH FREINDS AND FAMILY</div>
                                            </div>
                                        </div>
                                        <div className="FacilityEvent_info">
                                            <div className=''>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                        <img src="/assets/icons/briefcase.svg" style={{ width: "16px" }} alt="" />
                                                    </div>
                                                    <div className='FacilityEvent_timings'>
                                                        <div>Business Hrs</div>
                                                        <div class="events-info-timing">Mon-Fri 10 am - 8 pm</div>
                                                        <div class="events-info-timing">Sat-Sun Closed</div>
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                        <img src="/assets/icons/fee-icon.svg" style={{ width: "16px" }} alt="" />
                                                    </div>
                                                    <div className='FaciltyEvent_booking_hrs'>
                                                        <div>Booking Hrs</div>
                                                        <div class="events-info-timing">4 Hrs/Booking</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=''>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                        <img src="/assets/icons/fee-icon.svg" style={{ width: "16px" }} alt="" />
                                                    </div>
                                                    <div className='FacilityEvent_fees'>

                                                        <div>FEES</div>
                                                        <div class="events-info-timing">0$ for members</div>
                                                        <div class="events-info-timing">50$/tax for non members</div>
                                                    </div>
                                                </div>

                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                        <img src="/assets/icons/pax.svg" style={{ width: "16px" }} alt="" />
                                                    </div>
                                                    <div className='FaciltyEvent_booking_hrs'>
                                                        <div>PAX</div>
                                                        <div class="events-info-timing">20 PAX Booking Done</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Link to='/SportsFacilityDetails'><button className='facilityEvent_book'>Book Now</button></Link>

                                    </div>

                                </div>

                            </div>
                        </div>

                        :
                        <div className="FacilityEvent_main">
                            <div className='FacilityEvent'>
                                <img src="/images/HockeyNew.jpg" />
                                <div style={{ padding: "24px" }}>
                                    <div >
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div className='SportsEvents'>Indoor Hall</div>
                                            <div className='sports_availability'>10 Seats Available</div>
                                        </div>
                                        <div >

                                            <div className='FacilityEvent_desc'>Celebrate WITH FREINDS AND FAMILY</div>
                                        </div>
                                    </div>
                                    <div className="FacilityEvent_info">
                                        <div className=''>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/briefcase.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FacilityEvent_timings'>
                                                    <div>Business Hrs</div>
                                                    <div class="events-info-timing">Mon-Fri 10 am - 8 pm</div>
                                                    <div class="events-info-timing">Sat-Sun Closed</div>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/fee-icon.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FaciltyEvent_booking_hrs'>
                                                    <div>Booking Hrs</div>
                                                    <div class="events-info-timing">4 Hrs/Booking</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=''>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/fee-icon.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FacilityEvent_fees'>

                                                    <div>FEES</div>
                                                    <div class="events-info-timing">0$ for members</div>
                                                    <div class="events-info-timing">50$/tax for non members</div>
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/pax.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FaciltyEvent_booking_hrs'>
                                                    <div>PAX</div>
                                                    <div class="events-info-timing">20 PAX Booking Done</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Link to='/SportsFacilityDetails'><button className='facilityEvent_book'>Book Now</button></Link>
                                </div>



                            </div>

                            <div className='FacilityEvent'>
                                <img src="/images/HockeyNew.jpg" />
                                <div style={{ padding: "24px" }}>
                                    <div >
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div className='SportsEvents'>Indoor Hall</div>
                                            <div className='sports_availability'>10 Seats Available</div>
                                        </div>
                                        <div >

                                            <div className='FacilityEvent_desc'>Celebrate WITH FREINDS AND FAMILY</div>
                                        </div>
                                    </div>
                                    <div className="FacilityEvent_info">
                                        <div className=''>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/briefcase.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FacilityEvent_timings'>
                                                    <div>Business Hrs</div>
                                                    <div class="events-info-timing">Mon-Fri 10 am - 8 pm</div>
                                                    <div class="events-info-timing">Sat-Sun Closed</div>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/fee-icon.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FaciltyEvent_booking_hrs'>
                                                    <div>Booking Hrs</div>
                                                    <div class="events-info-timing">4 Hrs/Booking</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=''>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/fee-icon.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FacilityEvent_fees'>

                                                    <div>FEES</div>
                                                    <div class="events-info-timing">0$ for members</div>
                                                    <div class="events-info-timing">50$/tax for non members</div>
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/pax.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FaciltyEvent_booking_hrs'>
                                                    <div>PAX</div>
                                                    <div class="events-info-timing">20 PAX Booking Done</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <Link to='/SportsFacilityDetails'><button className='facilityEvent_book'>Book Now</button></Link>
                                </div>

                            </div>

                        </div>

                    }

                </div>
            </div>
        </div>
    )
}

export default SportsClub