import React from "react";
import "./gallery.css";
import { Image } from "antd";
import { Col, Row } from "antd";
import { useEffect } from "react";
import { useParams } from 'react-router-dom';

function Gallery() {
  const { name } = useParams();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [])

  const diwaliCelebrationBazar = Array.from({ length: 1000 }, (_, i) => `/assets/images/DiwaliCelebrationBazar/${i + 1}.JPG`);
  const DiwaliBazarHighTea = Array.from({ length: 984 }, (_, i) => `/assets/images/DiwaliBazarHighTea/${i + 1}.JPG`);
  const IASportCarnival = Array.from({ length: 71 }, (_, i) => `/assets/images/IASportCarnival/${i + 1}.JPG`);

  return (
    <div className="marginTopFromNavbar">
      {/* <div className="events_component_1">
        <div className="events_1">
          <h1>GALLERY</h1>
        </div>
        <div className="events_2">HOME - Gallery</div>
      </div> */}
      <div className="header_new">
          <div className="text-container">
            <div className="text">GALLERY</div>
            <div className="text2">Home - Gallery</div>
          </div>
      </div>

      {/* <div className="galleryHeading">{name}</div> */}
      {name === 'DiwaliCelebrationBazar' && <div className="galleryHeading">Diwali Celebration Bazar</div>}
      {name === 'IASportCarnival' && <div className="galleryHeading">IA Sports Carnival</div>}
      {name === 'DiwaliBazarHighTea' && <div className="galleryHeading">Diwali Bazar High Tea</div>}
      <div className="gallery-container">
        <div className="gallery_main">
          {name === 'DiwaliCelebrationBazar' && <Row className="gallery-imgs-row" gutter={[16, 48]}>
            {diwaliCelebrationBazar.map((path) => (
              <Col sm={12} lg={8}>
                <Image className="small_img" src={path} />
              </Col>
            ))}
          </Row>}
          {name === 'DiwaliBazarHighTea' && <Row className="gallery-imgs-row" gutter={[16, 48]}>
            {DiwaliBazarHighTea.map((path) => (
              <Col sm={12} lg={8}>
                <Image className="small_img" src={path} />
              </Col>
            ))}
          </Row>}
          {name === 'IASportCarnival' && <Row className="gallery-imgs-row" gutter={[16, 48]}>
            {IASportCarnival.map((path) => (
              <Col sm={12} lg={8}>
                <Image className="small_img" src={path} />
              </Col>
            ))}
          </Row>}
        </div>
      </div>

    </div>
  );
}

export default Gallery;
