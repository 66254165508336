import React from 'react'
import { Button, Divider, Form, message, Steps, theme } from 'antd';
import { useState } from 'react';
import BasicDetailsForm from './BasicDetailsForm';
import NextofKinForm from './NextofKinForm';
import ProposerForm from './ProposerForm';
import DeclarationForm from './DeclarationForm';
import "./MembershipForm.css"
import { Link } from 'react-router-dom';



function MembershipForm() {
    const [current, setCurrent] = useState(0);
    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };

    const [submitted, setsubmitted] = useState(false)

    const steps = [
        {
            title: 'Basic Details',
            content: <BasicDetailsForm />,
        },
        {
            title: 'Next of KIN',
            content: <NextofKinForm />,
        },
        {
            title: 'Proposer & Seconder',
            content: <ProposerForm />,
        },
        {
            title: 'Declaration',
            content: <DeclarationForm submitted={submitted} />,
        },
    ];

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));

    

    return (
        <div style={{ overflow: "hidden" }} className="marginTopFromNavbar">
            <div className="header_new">
                <div className="text-container">
                    <div className="text">MEMBERSHIP</div>
                </div>
            </div>
            <div style={{ margin: "0px 60px" }}>
                <Steps direction='horizontal' style={{ maxWidth: "700px", margin: "0px auto" }} labelPlacement="vertical" current={current} items={items} />
                <Divider />
                <Form style={{fontWeight:"500"}} layout='vertical'>
                    <div>
                        {steps[current].content}
                    </div>
                </Form>
                <div
                    style={{
                        marginTop: 24,
                    }}
                >
                    {current > 0 && (
                        <button className='membershipFormButton previousBtn'
                        style={{marginRight:"20px"}}
                            onClick={() => prev()}
                        >
                            BACK
                        </button>
                    )}
                    {current < steps.length - 1 && (
                        <button  className='membershipFormButton'  onClick={() => next()}>
                            NEXT
                        </button>
                    )}
                    {current === steps.length - 1 && (
                        <button className='membershipFormButton' onClick={()=>setsubmitted(true)}>
                            SUBMIT
                        </button>
                    )}
                    
                </div>
            </div>
        </div>
    )
}

export default MembershipForm