import "./history.css";
import { useEffect } from "react";
// import { Col, Row } from "antd";
import { useState } from "react";

function History() {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [])
  const [line, setLine] = useState("line-clamp");
  const [line2, setLine2] = useState("line-clamp");
  const [read, setRead] = useState(false);
  const [read2, setRead2] = useState(false);
  const readMore = (e) => {
    if (!read) {
      setRead(true);
      setLine("");
    }
    else {
      setRead(false);
      setLine("line-clamp");
    }
  }
  const readMore2 = (e) => {
    if (!read2) {
      setRead2(true);
      setLine2("");
    }
    else {
      setRead2(false);
      setLine2("line-clamp");
    }
  }

  return (
    <div style={{ overflow: "hidden" }} className="marginTopFromNavbar">
      {/* <div className="events_component_1">
        <div className="events_1">
          <h1>About Us</h1>
        </div>
        <div className="events_2">HOME - About Us</div>
      </div> */}

      <div className="header_new">
        <div className="text-container">
          <div className="text">ABOUT US</div>
          {/* <div className="text2">Home - Gallery</div> */}
        </div>
      </div>

      <div style={{ marginBottom: "100px", background: "#F5F5F5" }}>
        <div className="birth-section-container">


          <div className="birth-text-holder">
            <div className="marker-container">

              <div className="history-title-holder-main">
                Who We Are
              </div>
              <div className="red-highlight"></div>

              <div className={`birth-text ${line}`}>
                The Singapore Indian Association was established in 1923 with the objective of promoting the social, physical, intellectual, cultural and the general welfare of its members. When it was formed, the association projected itself as a pan-Indian, rather than narrowly ethnic, language, religion, caste or region-based organization. This marked it as significantly different from most other Indian organisations in Singapore.
                <br /><br />While the association was founded in 1923, its clubhouse was only completed in the 1950s. It is located at the historic Balestier Plain in Singapore, which has acquired formal heritage status for its concentration of a cluster of community associations and sports clubs, such as the Indian Association. In keeping with its early political leanings, the foundation stone of the clubhouse was laid by Jawaharlal Nehru on 18 June 1950.
                <br /><br />Since its beginning, the association has been extremely active in Singapore’s sports scene. Sports in which the association is active in include cricket, tennis, hockey, football and billiards. Many of its members and sportspeople also went on to represent Singapore in regional and international tournaments.

              </div>
              {read ? "" : <span className="birth-text readMore" onClick={(e) => readMore()} style={{ cursor: "pointer", color: 'black' }}>Read More</span>}
              {read ? <span className="birth-text readMore" onClick={(e) => readMore()} style={{ cursor: "pointer", color: 'black' }}>Read Less</span> : ""}
            </div>

          </div>

          <div className=" about-us-images">
            {/* <img
              className="birth-img-holder"
              src="/assets/images/history-demo-image.png"
              alt=""
            /> */}
            <div style={{ display: "flex" }} className="about-us-all-img">
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img
                  style={{ margin: "", height: "70%" }}
                  className="about-us-all-images about-us-all-images-first "
                  src="/images/about-us-first.jpg"
                  alt=""
                />
              </div>
              <div className="history-abous-us-images">
                <img style={{ marginBottom: "14px", height: "45%" }}
                  className="about-us-all-images about-us-all-images-second"
                  src="/images/about-us-third.jpg"
                  alt=""
                />
                <img

                  style={{ height: "45%" }}
                  className="about-us-all-images about-us-all-images-second"
                  src="/images/about-us-second.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>

        </div>


        <div className="birth-section-container">
          <div className="birth-image-container">
            <img
              className="birth-img-holder"
              src="/assets/images/birth.jpg"
              alt=""
            />
          </div>


          <div className="birth-text-holder">
            <div className="marker-container">
              <div className="marker">
                <div className="marker-date">1923</div>
              </div>

              <div className="history-title-holder">
                The Birth of<br></br>the Association
              </div>
              <div className="red-highlight"></div>

              <div className={`birth-text `}>
                Singapore Indian Association was formed as an ethnic sports club established in 1923 to serve our Indian communities and to promote the social, physical, intellectual, cultural and general welfare of its members. The journey over the past 99 years has seen SIA face challenges in its management and administration and has faded in its true identify in serving its members and communities adequately and to its purpose.

              </div>
              {/* {read?"":<span className="birth-text readMore" onClick={(e)=>readMore()} style={{cursor:"pointer",color:'black'}}>Read More</span>}
                {read?<span className="birth-text readMore" onClick={(e)=>readMore()} style={{cursor:"pointer",color:'black'}}>Read Less</span>:""} */}
            </div>

          </div>
        </div>

        <div className="birth-section-container birth-section-container-reverse margin-bottom-extra">

          <div className="birth-text-holder">
            <div className="marker-container">
              <div className="marker">
                <div className="marker-date">2008</div>
              </div>

              <div className="history-title-holder">
                Our Vision
              </div>
              <div className="red-highlight"></div>

              <div className="birth-text">
                For Singapore Indian Association to regain its image, reputation and transform its identity as a respectable and responsible association for its members and the Indian community.
              </div>
            </div>

          </div>

          <div
            className="birth-image-container"
          >
            <img
              className="birth-img-holder"
              src="/assets/images/vision.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="birth-section-container">
          <div xs={24} lg={12} className="birth-image-container">
            <img
              className="birth-img-holder"
              src="/assets/images/transformation.jpg"
              alt=""
            />
          </div>

          <div xs={24} lg={12}>
            <div className="birth-text-holder">
              <div className="marker-container">
                <div className="marker">
                  <div className="marker-date">
                    05 <br></br>Pillars
                  </div>
                </div>

                <div className="history-title-holder">
                  Our Transformation
                </div>
                <div className="red-highlight"></div>

                <div className={`birth-text ${line2}`}>
                  <p>5 key pillars as areas to focus our efforts for the transformation required with a proper structure and governance, which should drive the success of these pillars.</p>
                  {/* <ul> */}
                  <p><span className="transformPoints">Sports</span>  - We want to amplify the values of the current sports activities and enhance the positive impact it has on our members.</p>
                  <p><span className="transformPoints">Community Engagement</span> - We want to reach out and deepen relationships with the wider Indian communities, forge new partnerships with other reputable Indian organizations and strengthen existing collaboration with community leaders and institutions.</p>
                  <p><span className="transformPoints">Membership Benefits</span> - Our members are the most important asset of our Association. We will design a comprehensive membership benefits scheme to give all members access to a privileged experience. Improve and implement new programs and assistance services.</p>
                  <p><span className="transformPoints">Revenue Growth</span> – We need to ensure that SIA explores its full potential to drive revenue generation and growth opportunities through existing facilities and services.</p>
                  <p><span className="transformPoints">Better Future</span> – We need to drive this transformational roadmap to take our Association forward to the next level and to newer heights and for us to build a better and brighter future for our Singapore Indian Association.</p>
                  {/* </ul> */}
                </div>
                {read2 ? "" : <span className="birth-text readMore" onClick={(e) => readMore2()} style={{ cursor: "pointer", color: 'black' }}>Read More</span>}
                {read2 ? <span className="birth-text readMore" onClick={(e) => readMore2()} style={{ cursor: "pointer", color: 'black' }}>Read Less</span> : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default History;
