import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./navbar.css";
import { useLocation } from 'react-router-dom';
import { Button, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";

function Navbar() {
  const location = useLocation()
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    console.log(location.pathname)

  }, [location.pathname]);

  return (
    <div>
      <div className="navbar_desk_container">
        <div className="navbar_main desktop">
          <div>
            <Link to="/" className="link" style={{ borderBottom: "none" }}>
              <img src="/images/logo.png" className="logo" alt="" />
            </Link>
          </div>
          <div className="middle_navbar">
            <div className="middle_navbar_component">
              <div  >
                <Link className={`${location.pathname === '/' ? '' : 'link'} `} to="/">
                  Home
                </Link>
              </div>

              <div>
                {/* <Link className='link' to="/AllEventsFacilityBooking">Bookings</Link> */}
                <li class="nav-item dropdown">
                  <a
                    class="nav-link"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{ padding: "0" }}
                  >
                    About Us{" "}
                    <svg
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="dropdropicon"
                    >
                      <path
                        d="M0.992812 0.919667C0.666146 1.24633 0.666146 1.773 0.992812 2.09967L6.53281 7.63967C6.79281 7.89967 7.21281 7.89967 7.47281 7.63967L13.0128 2.09967C13.3395 1.773 13.3395 1.24633 13.0128 0.919668C12.6861 0.593001 12.1595 0.593001 11.8328 0.919668L6.99948 5.74633L2.16615 0.913001C1.84615 0.593001 1.31281 0.593001 0.992812 0.919667Z"
                        fill="#12101C"
                      />
                    </svg>
                  </a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown" style={{ fontSize: "15px" }}>
                    <Link
                      to="/history"
                      className="dropdown-item"
                    >
                      Who We Are
                    </Link>
                    <Link to="/management-committee" className="dropdown-item">
                      Mgmt. Committee
                    </Link> 
                    <Link to="/history" className="dropdown-item">
                      General Office
                    </Link>
                    {/* <Link to="/history" className="dropdown-item">
                      Constitution
                    </Link> */}
                    <Link to="/history" className="dropdown-item">
                      Women Wing
                    </Link>
                  </div>
                </li>
              </div>

              <div>
                {/* <Link className='link' to="/AllEventsFacilityBooking">Bookings</Link> */}
                <li class="nav-item dropdown">
                  <a
                    class="nav-link"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{ padding: "0" }}
                  >
                    Events{" "}
                    <svg
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="dropdropicon"
                    >
                      <path
                        d="M0.992812 0.919667C0.666146 1.24633 0.666146 1.773 0.992812 2.09967L6.53281 7.63967C6.79281 7.89967 7.21281 7.89967 7.47281 7.63967L13.0128 2.09967C13.3395 1.773 13.3395 1.24633 13.0128 0.919668C12.6861 0.593001 12.1595 0.593001 11.8328 0.919668L6.99948 5.74633L2.16615 0.913001C1.84615 0.593001 1.31281 0.593001 0.992812 0.919667Z"
                        fill="#12101C"
                      />
                    </svg>
                  </a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown" style={{ fontSize: "15px" }}>
                    <Link
                      to="/upcoming-events"
                      className="dropdown-item"
                    >
                      Upcoming Events
                    </Link>
                    <Link to="/galleryFolder" className="dropdown-item">
                      Gallery - Past Events
                    </Link>
                  </div>
                </li>
              </div>

              {/* <div>
                <Link className={`${location.pathname === '/galleryFolder' || location.pathname === '/gallery' ? '' : 'link'} `} to="/galleryFolder">
                  Gallery
                </Link>
              </div> */}
              {/* <div>
                <Link className={`${location.pathname === '/upcoming-events' ? '' : 'link'} `} to="/upcoming-events">
                  Events
                </Link>
              </div> */}
              <div>
                {/* <Link className='link' to="/AllEventsFacilityBooking">Bookings</Link> */}
                <li class="nav-item dropdown">
                  <a
                    class="nav-link"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Bookings{" "}
                    <svg
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="dropdropicon"
                    >
                      <path
                        d="M0.992812 0.919667C0.666146 1.24633 0.666146 1.773 0.992812 2.09967L6.53281 7.63967C6.79281 7.89967 7.21281 7.89967 7.47281 7.63967L13.0128 2.09967C13.3395 1.773 13.3395 1.24633 13.0128 0.919668C12.6861 0.593001 12.1595 0.593001 11.8328 0.919668L6.99948 5.74633L2.16615 0.913001C1.84615 0.593001 1.31281 0.593001 0.992812 0.919667Z"
                        fill="#12101C"
                      />
                    </svg>
                  </a>
                  <div  class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <Link
                      to="/AllEventsFacilityBooking"
                      className="dropdown-item"
                    >
                      Events Facility
                    </Link>
                    <Link to="/SportsFacility" className="dropdown-item">
                      Sports Facility
                    </Link>
                    <Link to="/SportsClub" className="dropdown-item">
                      Sports Club
                    </Link>
                  </div>
                </li>
              </div>
              <div>
                <Link className={`${location.pathname === '/contact-us' ? '' : 'link'} `} to="/contact-us">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
          <Link to="/login">
            <div className="btn">
              <button style={{ cursor: "pointer", fontSize: "13px" }}>Member Login</button>
            </div>
          </Link>

          {/* <li class="nav-item dropdown userloggedIn">
            <a
              class="nav-link"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div>
                <img src="assets/icons/usericon.png" alt="" />
                <span>John Smith</span>
              </div>
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <div className="dropdown-item" style={{ width: "20px" }}>
                <a href="/">Log out</a>
              </div>
            </div>
          </li> */}
        </div>
      </div>
      <div className="navbar_main mobile">
        <div>
          <img src="/images/logo.png" className="logo" alt="" />
        </div>
        <Button onClick={showDrawer} style={{ border: "none", padding: "4px" }}>
          <MenuOutlined style={{ color: "#F31237", fontSize: '16px', }} />
        </Button>
      </div>
      <div style={{ textAlign: "center" }}>
        <Drawer

          placement="right"
          width="100vw"
          onClose={onClose}
          getContainer={false}
          visible={visible}

        >
          <p onClick={onClose} style={{ fontSize: "16px" }}>
            <Link to="/" className={`${location.pathname === '/' ? '' : 'link'} `}>
              Home
            </Link>
          </p>
          <p onClick={onClose} style={{ fontSize: "16px" }}>

            <div>
              {/* <Link className='link' to="/AllEventsFacilityBooking">Bookings</Link> */}
              <li class="nav-item dropdown">
                <a
                  class="nav-link"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{ padding: "0" }}
                >
                  About Us{" "}
                  <svg
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="dropdropicon"
                  >
                    <path
                      d="M0.992812 0.919667C0.666146 1.24633 0.666146 1.773 0.992812 2.09967L6.53281 7.63967C6.79281 7.89967 7.21281 7.89967 7.47281 7.63967L13.0128 2.09967C13.3395 1.773 13.3395 1.24633 13.0128 0.919668C12.6861 0.593001 12.1595 0.593001 11.8328 0.919668L6.99948 5.74633L2.16615 0.913001C1.84615 0.593001 1.31281 0.593001 0.992812 0.919667Z"
                      fill="#12101C"
                    />
                  </svg>
                </a>
                <div onClick={onClose}  class="dropdown-menu" aria-labelledby="navbarDropdown" style={{ fontSize: "15px" }}>
                  <Link
                    to="/history"
                    className="dropdown-item"
                  >
                    Who We Are
                  </Link>
                  <Link to="/history" className="dropdown-item">
                    Management Committee
                  </Link>
                  <Link to="/history" className="dropdown-item">
                    General Office
                  </Link>
                  <Link to="/history" className="dropdown-item">
                    Constitution
                  </Link>
                  <Link to="/history" className="dropdown-item">
                    Women Wing
                  </Link>
                </div>
              </li>
            </div>

            {/* <Link  className={`${location.pathname === '/history' ?'' : 'link'} `} to="/history">

            About Us

          </Link> */}
          </p>
          <p onClick={onClose} style={{ fontSize: "16px" }}>
          <li class="nav-item dropdown">
                  <a
                    class="nav-link"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{ padding: "0" }}
                  >
                    Events{" "}
                    <svg
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="dropdropicon"
                    >
                      <path
                        d="M0.992812 0.919667C0.666146 1.24633 0.666146 1.773 0.992812 2.09967L6.53281 7.63967C6.79281 7.89967 7.21281 7.89967 7.47281 7.63967L13.0128 2.09967C13.3395 1.773 13.3395 1.24633 13.0128 0.919668C12.6861 0.593001 12.1595 0.593001 11.8328 0.919668L6.99948 5.74633L2.16615 0.913001C1.84615 0.593001 1.31281 0.593001 0.992812 0.919667Z"
                        fill="#12101C"
                      />
                    </svg>
                  </a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown" style={{ fontSize: "15px" }}>
                    <Link
                      to="/upcoming-events"
                      className="dropdown-item"
                    >
                      Upcoming Events
                    </Link>
                    <Link to="/galleryFolder" className="dropdown-item">
                      Gallery - Past Events
                    </Link>
                  </div>
                </li>
          </p>
      
          <p style={{ fontSize: "16px" }}>
            <div>
              {/* <Link className='link' to="/AllEventsFacilityBooking">Bookings</Link> */}
              <li class="nav-item dropdown">
                <a
                  class="nav-link"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{ padding: "0" }}
                >
                  Bookings{" "}
                  <svg
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="dropdropicon"
                  >
                    <path
                      d="M0.992812 0.919667C0.666146 1.24633 0.666146 1.773 0.992812 2.09967L6.53281 7.63967C6.79281 7.89967 7.21281 7.89967 7.47281 7.63967L13.0128 2.09967C13.3395 1.773 13.3395 1.24633 13.0128 0.919668C12.6861 0.593001 12.1595 0.593001 11.8328 0.919668L6.99948 5.74633L2.16615 0.913001C1.84615 0.593001 1.31281 0.593001 0.992812 0.919667Z"
                      fill="#12101C"
                    />
                  </svg>
                </a>
                <div onClick={onClose}  class="dropdown-menu" aria-labelledby="navbarDropdown" style={{ width: "18rem", fontSize: "15px" }}>
                  <Link
                    to="/AllEventsFacilityBooking"
                    className="dropdown-item"
                  >
                    Events Facility
                  </Link>
                  <Link to="/SportsFacility" className="dropdown-item">
                    Sports Facility
                  </Link>
                  <Link to="/SportsClub" className="dropdown-item">
                    Sports Club
                  </Link>
                </div>
              </li>
            </div>
          </p>

          <p onClick={onClose} style={{ fontSize: "16px" }}>
            <Link className={`${location.pathname === '/contact-us' ? '' : 'link'} `} to="/contact-us">Contact Us</Link>
          </p>

          <p onClick={onClose}>
            {/* <Link to="/login">
          <div className="btn" style={{padding : "0"}}>
            <button style={{ cursor: "pointer" }}>Member Login</button>
          </div>
        </Link> */}
            <p onClick={onClose} style={{ fontSize: "16px" }}>
              <Link className={`${location.pathname === '/login' ? '' : 'link'} `} to="/login">Member Login</Link>
            </p>
          </p>
        </Drawer>
      </div>
    </div>

  );
}

export default Navbar;
