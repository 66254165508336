import React from "react";
import "./Events.css";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { Link } from 'react-router-dom'
import { Col, Row } from "antd";

export default function UpcomingEvents() {
  const scrollToTop = () => {
    window.scrollTo(0, 0)
}
useEffect(()=>{
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
},[])
  return (
    <div className="marginTopFromNavbar">
     {/* <div className="events_component_1">
        <div className="events_1">
          <h1>UPCOMING EVENTS</h1>
        </div>
        <div className="events_2">HOME - Upcoming Events</div>
      </div> */}

    {/* <div className="header_new">
          <div className="text-container">
            <div className="text">UPCOMING EVENTS</div>
            <div className="text2">Home - Gallery</div>
          </div>
      </div> */}

    <div className="main-upcoming">
      {/* <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 1.0 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 1 }
        }}

      > */}
        {/* <div className="header" style={{color:"#FFFFFF"}}>UPCOMING EVENTS</div> */}
        
      {/* </motion.div> */}
      {/* <hr /> */}

      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 1.0 }}
        variants={{
          visible: { y: 0 },
          hidden: { y: 200 }
        }}
        style={{ overflow: "hidden",width : "100%" ,marginBottom:"50px"}}
      >
        <div className="events ">
         {/* <Link className="eventBoxContainer" to="/events">
            <div className="event-1">
              <img src="images/image9.jpg" alt="" />
              <div className="desc">
                <div className="calendar">
                  <div className="month">July</div>
                  <div className="date">18</div>
                </div>
                <div className="descriptions">
                  <div className="first">
                  SIA New Year’s Party
                  </div>
                  <div className="second_desc">
                    Loreum ipsum is dummy text. Loreum ipsum is dummy text.
                  </div>
                </div>
              </div>
            </div>
          </Link>
          <Link className="eventBoxContainer" to="/events">
            <div className="event-2">
              <img src="images/image8.jpg" alt="" />
              <div className="desc">
                <div className="calendar">
                  <div className="month">July</div>
                  <div className="date">18</div>
                </div>
                <div className="descriptions">
                  <div className="first">
                    SIA Pongal Festival (2023)
                  </div>
                  <div className="second_desc">
                    Loreum ipsum is dummy text. Loreum ipsum is dummy text.
                  </div>
                </div>
              </div>
            </div>
          </Link>
          <Link className="eventBoxContainer" to="/events">
            <div className="event-3">
              <img src="images/event3.png" alt="" />
              <div className="desc">
                <div className="calendar">
                  <div className="month">July</div>
                  <div className="date">18</div>
                </div>
                <div className="descriptions">
                  <div className="first">Tasty Food & Drinks Festrival-2022.</div>
                  <div className="second_desc">
                    Loreum ipsum is dummy text. Loreum ipsum is dummy text.
                  </div>
                </div>
              </div>
            </div>
          </Link> */}
        
         
          <Row className="gallery-imgs-row">
          <Col sm={12} lg={8}>
                  <div className="upcoming-event-image">
                  <img
                    className="small_img_folder"
                    src="/assets/images/Rectangle 4159.jpg"
                  alt=""
                  />
                  
                  <div className="text-on-image">
                    <div className="text-title">
                    Traditional Folk Music Festival.
                    </div>
                    <div className="text-on-image-btn">
                    <Link to="/events">  <div className="text-on-image-btn">
                      <button> View Details</button>
                    </div>
                    </Link>
                    </div>
                  </div>
                  </div>
                 
                </Col>
                <Col sm={12} lg={8}>
         
                <div className="upcoming-event-image">
                  <img
                    className="small_img_folder"
                    src="/assets/images/Rectangle 83.jpg"
                  alt=""
                  />
                  
                  <div className="text-on-image">
                    <div className="text-title">
                    Traditional Folk Music Festival.
                    </div>
                    <div className="text-on-image-btn">
                    <Link to="/events">  <div className="text-on-image-btn">
                      <button> View Details</button>
                    </div>
                    </Link>
                    </div>
                  </div>
                  </div>
                
                </Col>
                <Col sm={12} lg={8}>
           
                <div className="upcoming-event-image">
                  <img
                    className="small_img_folder"
                    src="/assets/images/Rectangle 4159.jpg"
                  alt=""
                  />
                  
                  <div className="text-on-image">
                    <div className="text-title">
                    Traditional Folk Music Festival.
                    </div>
                    
                    <Link to="/events">  <div className="text-on-image-btn">
                      <button> View Details</button>
                    </div>
                    </Link>
                  </div>
                  </div>
                 
                </Col>
                <Col sm={12} lg={8}>
           
                <div className="upcoming-event-image">
                  <img
                    className="small_img_folder"
                    src="/assets/images/Rectangle 4159.jpg"
                  alt=""
                  />
                  
                  <div className="text-on-image">
                    <div className="text-title">
                    Traditional Folk Music Festival.
                    </div>
                    <div className="text-on-image-btn">
                    <Link to="/events">  <div className="text-on-image-btn">
                      <button> View Details</button>
                    </div>
                    </Link>
                    </div>
                  </div>
                  </div>
                 
                </Col>
                <Col sm={12} lg={8}>
         
                <div className="upcoming-event-image">
                  <img
                    className="small_img_folder"
                    src="/assets/images/Rectangle 4159.jpg"
                  alt=""
                  />
                  
                  <div className="text-on-image">
                    <div className="text-title">
                    Traditional Folk Music Festival.
                    </div>
                    <div className="text-on-image-btn">
                    <Link to="/events">  <div className="text-on-image-btn">
                      <button> View Details</button>
                    </div>
                    </Link>
                    </div>
                  </div>
                  </div>
                
                </Col>
                
                <Col sm={12} lg={8}>
           
                <div className="upcoming-event-image">
                  <img
                    className="small_img_folder"
                    src="/assets/images/Rectangle 86.jpg"
                  alt=""
                  />
                  
                  <div className="text-on-image">
                    <div className="text-title">
                    Traditional Folk Music Festival.
                    </div>
                    <div className="text-on-image-btn">
                    <Link to="/events">  <div className="text-on-image-btn">
                      <button> View Details</button>
                    </div>
                    </Link>
                    </div>
                  </div>
                  </div>
               
                </Col>
               
          </Row>

        </div></motion.div>
        {/* <Link to='/events'>
        <button  onClick={scrollToTop} style={{ cursor: "pointer",fontSize:"16px",marginBottom:"53px" ,padding:"12px 31px"}}>Find More</button></Link> */}
    </div>
    
    </div>

  );
}
