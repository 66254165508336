import React from 'react'
import './events.css'
import styles from "./Events.module.css";
import { motion } from "framer-motion";
import { Link } from 'react-router-dom'


function events() {
 
  
  return (
    <>
      <div className='events_main'>
        {/* <div className='events_component_1'>
          <div className="events_1">
            <h1>INDIAN FOLK DANCE FEST</h1>
          </div>
          <div className='events_2'>
            HOME - Upcoming Events - Indian Folk Dance Fest
          </div>
        </div> */}

      <div className="header_new">
          <div className="text-container">
            <div className="text">EVENTS</div>
            <div className="text2">Home - Events</div>
          </div>
      </div>

        <div className='events_component_2'>
          <div className="first">
            <img src="/images/Rectangle 84.png" alt="" />
          </div>
          <div className="second">
            <div className="head_1">ABOUT THIS EVENT</div>
            <hr />
            <div className="head_2">
              INDIAN FOLK DANCE FEST
            </div>
            <div className="description_events_2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Condimentum diam orci pretium a pharetra, feugiat cursus. Dictumst risus, sem egestas odio cras adipiscing vulputate. Nisi, risus in suscipit non. Non commodo volutpat, pharetra, vel.
            </div>
            <div className="when_where">
              <div className="where">
                <span>WHERE</span>
                <div>
                  SIA Club
                </div>
                <div>
                  69 Balestier Road
                </div>
                <div>
                  Singapore
                </div>
              </div>
              <div className="when">
                <span>WHEN</span>
                <div>
                  Sunday
                </div>
                <div>
                  24th July, 2022
                </div>
              </div>
              <div className="when">
                <span>FEES</span>
                <div>
                  0$ for members

                </div>
                <div>
                  50$/pax for non members
                </div>
              </div>
            </div>
            <Link to="/EventsFacilityBooking">
              <div className="btn">
                <button>BOOK NOW</button>
              </div>
            </Link>
          </div>
        </div>

        <div className='main-component-of-event'>
          <div className="header events_header">EVENT DESCRIPTION</div>
          <hr  />
          <div className='main-about-events'>
          <div className='about-events'>
          A cultural event is an organized activity that aims to showcase or celebrate a particular aspect of a society's culture, such as its art, music, dance, literature, history, or traditions. It may take the form of a festival, exhibition, performance, or ceremony, and can be held in various settings such as museums, theaters, outdoor spaces, or cultural centers. Cultural events provide an opportunity for individuals to come together, learn, and appreciate the richness and diversity of different cultures, as well as to promote cultural exchange and understanding.
           </div>
          
            </div>
          <ul className='points-about-events'>
            <li>Date and time of the event</li>
            <li>Type of event (festival, exhibition, performance, ceremony, etc.)</li>
            <li>Activities and attractions (e.g. live performances, workshops, food vendors, cultural displays, etc.)</li>
            <li>Location and venue</li>
          
          </ul>
        </div>
        {/* <div className="main-upcoming"> 
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1.0 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 1 }
            }}

          >
            <div className="header">FIND MORE EVENTS</div>
          </motion.div>
          <hr />

          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1.0 }}
            variants={{
              visible: { y: 0 },
              hidden: { y: 200 }
            }}
            style={{ overflow: "hidden" }}
          >
            <div className="events " >
              <div className="event-1">
                <img src="images/event1.png" alt="" />
                <div className="desc">
                  <div className="calendar">
                    <div className="month">July</div>
                    <div className="date">18</div>
                  </div>
                  <div className="descriptions">
                    <div className="first">
                      Summer Bazaar 2022 - Connected To India.
                    </div>
                    <div className="second_desc">
                      Loreum ipsum is dummy text. Loreum ipsum is dummy text.
                    </div>
                  </div>
                </div>
              </div>
              <div className="event-2">
                <img src="images/event2.png" alt="" />
                <div className="desc">
                  <div className="calendar">
                    <div className="month">July</div>
                    <div className="date">18</div>
                  </div>
                  <div className="descriptions">
                    <div className="first">Traditional Folk Music Festivals.</div>
                    <div className="second_desc">
                      Loreum ipsum is dummy text. Loreum ipsum is dummy text.
                    </div>
                  </div>
                </div>
              </div>
              <div className="event-3">
                <img src="images/event3.png" alt="" />
                <div className="desc">
                  <div className="calendar">
                    <div className="month">July</div>
                    <div className="date">18</div>
                  </div>
                  <div className="descriptions">
                    <div className="first">Tasty Food & Drinks Festrival-2022.</div>
                    <div className="second_desc">
                      Loreum ipsum is dummy text. Loreum ipsum is dummy text.
                    </div>
                  </div>
                </div>
              </div>
            </div></motion.div> 
        </div>*/}

          {/* UPCOMING Events ...................... */}

          <div style={{backgroundColor:"#1E1E1E",width:"100vw",paddingTop:"56px",paddingBottom:"56px"}}>
    <div className={styles.mainupcoming}>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 1.0 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 1 }
        }}

      >
        <div className="header" style={{color:"#FFFFFF"}}>UPCOMING EVENTS</div>
        
      </motion.div>
      <hr />

      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 1.0 }}
        variants={{
          visible: { y: 0 },
          hidden: { y: 200 }
        }}
        style={{ overflow: "hidden",width : "100%" }}
      >
        <div className={styles.events}>
         {/* <Link className="eventBoxContainer" to="/events">
            <div className="event-1">
              <img src="images/image9.jpg" alt="" />
              <div className="desc">
                <div className="calendar">
                  <div className="month">July</div>
                  <div className="date">18</div>
                </div>
                <div className="descriptions">
                  <div className="first">
                  SIA New Year’s Party
                  </div>
                  <div className="second_desc">
                    Loreum ipsum is dummy text. Loreum ipsum is dummy text.
                  </div>
                </div>
              </div>
            </div>
          </Link>
          <Link className="eventBoxContainer" to="/events">
            <div className="event-2">
              <img src="images/image8.jpg" alt="" />
              <div className="desc">
                <div className="calendar">
                  <div className="month">July</div>
                  <div className="date">18</div>
                </div>
                <div className="descriptions">
                  <div className="first">
                    SIA Pongal Festival (2023)
                  </div>
                  <div className="second_desc">
                    Loreum ipsum is dummy text. Loreum ipsum is dummy text.
                  </div>
                </div>
              </div>
            </div>
          </Link>
          <Link className="eventBoxContainer" to="/events">
            <div className="event-3">
              <img src="images/event3.png" alt="" />
              <div className="desc">
                <div className="calendar">
                  <div className="month">July</div>
                  <div className="date">18</div>
                </div>
                <div className="descriptions">
                  <div className="first">Tasty Food & Drinks Festrival-2022.</div>
                  <div className="second_desc">
                    Loreum ipsum is dummy text. Loreum ipsum is dummy text.
                  </div>
                </div>
              </div>
            </div>
          </Link> */}
          <div style={{position:"relative"}}>
            <img className={styles.eventsImage} src="images/image9.jpg"/>
            <div className={styles.image_Overlay}></div>
            <div className="after"></div>
            <div className={styles.desc}>
                {/* <div className="calendar">
                  <div className="month">July</div>
                  <div className="date">18</div>
                </div> */}
                <div className={styles.descriptions}>
                  <div className={styles.first}  style={{textAlign:"left"}} >
                  SIA New Year’s Party (2023)
                  </div>
                  <div className={styles.first}style={{color:"#ffffff",fontWeight:"800"}}>
                  <div className={styles.second} style={{textAlign:"left"}}>July 18</div>
                  {/* <div className=""  style={{textAlign:"left"}}></div> */}
                </div>
                </div>
                </div>
          </div>
          <div style={{position:"relative"}}>
            <img className={styles.eventsImage}src="images/image8.jpg"/>
            <div className={styles.image_Overlay}></div>
            <div className="after"></div>
            <div className={styles.desc}>
                {/* <div className="calendar">
                  <div className="month">July</div>
                  <div className="date">18</div>
                </div> */}
                <div  className={styles.descriptions}>
                  <div className={styles.first} style={{textAlign:"left"}} >
                    SIA Pongal Festival (2023)
                  </div>
                  <div className={styles.first}style={{color:"#ffffff",fontWeight:"800"}}>
                  <div className={styles.second} style={{textAlign:"left"}}>July 18</div>
                  {/* <div className="">18</div> */}
                </div>
                </div>
                </div>
                  
             
          </div>
          <div style={{position:"relative"}}>
            <img className={styles.eventsImage} src="images/event3.png"/>
            <div className={styles.image_Overlay}></div>
            <div className="after"></div>
            <div className={styles.desc}>
                {/* <div className="calendar">
                  <div className="month">July</div>
                  <div className="date">18</div>
                </div> */}
                <div className={styles.descriptions}>
                  <div  className={styles.first} style={{textAlign:"left"}} >Tasty Food & Drinks Festrival-2022.</div>
                  <div className={styles.first}style={{color:"#ffffff",fontWeight:"800"}}>
                  <div className={styles.second} style={{textAlign:"left"}}>July 18</div>
                  {/* <div className="">18</div> */}
                </div>
                </div>
              
                
              </div>
          </div>
          <div style={{position:"relative"}}>
            <img className={styles.eventsImage} src="images/Rectangle 4159 (3).png"/>
            <div className={styles.image_Overlay}></div>
            <div className="after"></div>
            <div className={styles.desc}>
                {/* <div className="calendar">
                  <div className="month">July</div>
                  <div className="date">18</div>
                </div> */}
               <div className={styles.descriptions}>
                  <div  className={styles.first} style={{textAlign:"left"}} >Tasty Food & Drinks Festrival-2022.</div>
                  <div className={styles.first}style={{color:"#ffffff",fontWeight:"800"}}>
                  <div className={styles.second} style={{textAlign:"left"}}>July 18</div>
                  {/* <div className="">18</div> */}
                </div>
                </div>
                </div>
          
          </div>

        </div></motion.div>
        {/* <Link to='/events'>
        <button  style={{ cursor: "pointer",fontSize:"16px",marginBottom:"53px" ,padding:"12px 31px"}}>Find More</button></Link> */}
    </div>
    
    </div>

      </div>
    </>
  )
}

export default events