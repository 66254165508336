import { Form, Input } from 'antd'
import React from 'react'

function NextofKinForm() {
    return (
        <div>
            <p className="font-weight-bold">Emergency Contact Person 1</p>
            <div className="basicFormRow">
                <div className="besicFormInnerDiv">
                    <Form.Item label='Name*' name='title' style={{ width: "100%" }}>
                        <Input />
                    </Form.Item>
                </div>
                <div className="besicFormInnerDiv">
                    <Form.Item label='Relationship*' name='title' style={{ width: "100%" }}>
                        <Input/>
                    </Form.Item>
                </div>
            </div>
            <div className="basicFormRow">
                <div className="besicFormInnerDiv">
                    <Form.Item label='Mobile Number**' name='title' style={{ width: "100%" }}>
                        <Input />
                    </Form.Item>
                </div>
                <div className="besicFormInnerDiv">
                    <Form.Item label='Home Contact Number**' name='title' style={{ width: "100%" }}>
                        <Input/>
                    </Form.Item>
                </div>
            </div>
            <p className="font-weight-bold">Emergency Contact Person 2</p>
            <div className="basicFormRow">
                <div className="besicFormInnerDiv">
                    <Form.Item label='Name*' name='title' style={{ width: "100%" }}>
                        <Input />
                    </Form.Item>
                </div>
                <div className="besicFormInnerDiv">
                    <Form.Item label='Relationship*' name='title' style={{ width: "100%" }}>
                        <Input/>
                    </Form.Item>
                </div>
            </div>
            <div className="basicFormRow">
                <div className="besicFormInnerDiv">
                    <Form.Item label='Mobile Number**' name='title' style={{ width: "100%" }}>
                        <Input />
                    </Form.Item>
                </div>
                <div className="besicFormInnerDiv">
                    <Form.Item label='Home Contact Number**' name='title' style={{ width: "100%" }}>
                        <Input/>
                    </Form.Item>
                </div>
            </div>
        </div>
    )
}

export default NextofKinForm