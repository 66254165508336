import React from 'react'
import { Image } from "antd";
import { Col, Row } from "antd";
import { useEffect } from "react";
import './AllEventsFacilityBookng.css';
import { Link } from 'react-router-dom';

function EventsFacilityBooking() {
  useEffect(()=>{
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
},[])

  return (
    <div className='marginTopFromNavbar'>
      {/* <div className="events_component_1">
        <div className="events_1">
          <h1>EVENTS FACILITY</h1>
        </div>
        <div className="events_2">Home - Bookings - Events Facility</div>
      </div> */}
      {/* <div className="header_new">
          <div className="text-container">
            <div className="text">EVENTS FACILITY</div>
            <div className="text2">Home - Bookings - Events Facility</div>
          </div>
      </div> */}
      <div className="intro-container">

        <div className="intro-text-header">A space to work, meet & have fun</div>

        <div className="separator"></div>

        <div className="intro-text">Events facility booking involves reserving a space for a particular event or gathering, such as a conference, wedding, or concert. The process typically includes selecting a date, time, and location, as well as negotiating pricing and any additional services or amenities needed for the event. It is important to book an events facility well in advance to ensure availability, and to read and understand the terms and conditions of the booking agreement.</div>
      </div>

      {/* <div className="events-card-holder"> */}
      {/* <Row>
          <Col className="card-col" lg={12} sm={24}>
            <div className="events-card">
              <div className="img-holder">
                <Image src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png" />
              </div>
              <div className="content-holder">
                <div className="card-header">Indoor Hall</div>
                <div className="card-sub-header">CELEBRATE WITH FRIENDS WITH FAMILY</div>

                <div className="detail-holder">
 
                <Row style={{paddingBottom:"15px"}}>
                  <Col span={12}>
                    <div className="detail-label-text">
                      <div className="detail-icon-label-container">
                        <div className="detail-icon-business-hrs">
                        <img src="/assets/icons/briefcase.svg" style={{width:"16px"}} alt="" />
                        </div>
                        <div className="detail-label">Business Hrs</div>
                      </div>
                      <div className="detail-text">Mon-Fri 10 am - 8 pm</div>
                      <div className="detail-text">Sat-Sun Closed</div>
                    </div>
                  </Col>
                  <Col span={12}>
                  <div className="detail-label-text">
                      <div className="detail-icon-label-container">
                        <div className="detail-icon-business-hrs">
                        <img src="/assets/icons/fee-icon.svg" style={{width:"16px"}} alt="" />
                        </div>
                        <div className="detail-label">Fees</div>
                      </div>
                      <div className="detail-text">$0 for Members</div>
                      <div className="detail-text">$50/pax for non-member</div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <div className="detail-label-text">
                      <div className="detail-icon-label-container">
                        <div className="detail-icon-business-hrs">
                        <img src="/assets/icons/briefcase.svg" style={{width:"16px"}} alt="" />

                        </div>
                        <div className="detail-label">Booking Hrs</div>
                      </div>
                      <div className="detail-text">4 Hrs/Booking</div>
                    </div>
                  </Col>
                  <Col span={12}>
                  
                  </Col>
                </Row>
              
                </div>
                <div style={{padding:"25px"}}>
                  <Link to="/EventsFacilityBooking">
                  <button className='facility_booking_btn'>Book Now</button>
                  </Link>
                </div>
              </div>
             
            </div>
          </Col>

          <Col className="card-col" lg={12} sm={24}>
            <div className="events-card">
              <div className="img-holder">
                <Image src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png" />
              </div>
              <div className="content-holder">
                <div className="card-header">Indoor Hall</div>
                <div className="card-sub-header">CELEBRATE WITH FRIENDS WITH FAMILY</div>

                <div className="detail-holder">
 
                <Row style={{paddingBottom:"15px"}}>
                  <Col span={12}>
                    <div className="detail-label-text">
                      <div className="detail-icon-label-container">
                        <div className="detail-icon-business-hrs">
                        <img src="/assets/icons/briefcase.svg" style={{width:"16px"}} alt="" />
                        </div>
                        <div className="detail-label">Business Hrs</div>
                      </div>
                      <div className="detail-text">Mon-Fri 10 am - 8 pm</div>
                      <div className="detail-text">Sat-Sun Closed</div>
                    </div>
                  </Col>
                  <Col span={12}>
                  <div className="detail-label-text">
                      <div className="detail-icon-label-container">
                        <div className="detail-icon-business-hrs">
                        <img src="/assets/icons/fee-icon.svg" style={{width:"16px"}} alt="" />
                        </div>
                        <div className="detail-label">Fees</div>
                      </div>
                      <div className="detail-text">$0 for Members</div>
                      <div className="detail-text">$50/pax for non-member</div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <div className="detail-label-text">
                      <div className="detail-icon-label-container">
                        <div className="detail-icon-business-hrs">
                        <img src="/assets/icons/briefcase.svg" style={{width:"16px"}} alt="" />

                        </div>
                        <div className="detail-label">Booking Hrs</div>
                      </div>
                      <div className="detail-text">4 Hrs/Booking</div>
                    </div>
                  </Col>
                  <Col span={12}>
                  
                  </Col>
                </Row>
              
                </div>
                <div style={{padding:"25px"}}>
                  <Link to="/EventsFacilityBooking">
                  <button className='facility_booking_btn'>Book Now</button>
                  </Link>
                </div>
              </div>
             
            </div>
          </Col>
          
        </Row> */}

      <div className="FacilityEvent_main">
        <div className='FacilityEvent'>
          <img style={{ height: '320px', objectFit: 'cover' }} src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png" />
          <div style={{ padding: "24px" }}>
            <div className='FacilityEvent_name'>Indoor Hall</div>
            <div className='FacilityEvent_desc'>Celebrate WITH FREINDS AND FAMILY</div>
            <div className='FacilityEvent_info'>
              <div style={{ display: 'flex', justifyContent:'flex-start', alignItems:'baseline'}}>
                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                  <img src="/assets/icons/briefcase.svg" style={{ width: "16px" }} alt="" />
                </div>
                <div className='FacilityEvent_timings'>
                  <div className='card_details_text'>Business Hrs</div>
                  <div class="events-info-timing">Mon-Fri 10 am - 8 pm</div>
                  <div class="events-info-timing">Sat-Sun Closed</div>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent:'flex-start', alignItems:'baseline' }}>
                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                  <img src="/assets/icons/fee-icon.svg" style={{ width: "16px" }} alt="" />
                </div>
                <div className='FacilityEvent_fees'>

                  <div>FEES</div>
                  <div class="events-info-timing">0$ for members</div>
                  <div class="events-info-timing">50$/tax for non members</div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent:'flex-start', alignItems:'baseline' }}>
              <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                <img src="/assets/icons/fee-icon.svg" style={{ width: "16px" }} alt="" />
              </div>
              <div className='FaciltyEvent_booking_hrs'>
                <div>Booking Hrs</div>
                <div class="events-info-timing">4 Hrs/Booking</div>
              </div>
            </div>

            <Link to="/EventsFacilityBooking">
              <button className='facility_booking_btn'>Book Now</button>
            </Link>
          </div>



        </div>

        <div className='FacilityEvent'>
          <img style={{ height: '320px', objectFit: 'cover' }} src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png" />
          <div style={{ padding: "24px" }}>
            <div className='FacilityEvent_name'>Indoor Hall</div>
            <div className='FacilityEvent_desc'>Celebrate WITH FREINDS AND FAMILY</div>
            <div className='FacilityEvent_info'>
              <div style={{ display: 'flex', justifyContent:'flex-start', alignItems:'baseline'}}>
                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                  <img src="/assets/icons/briefcase.svg" style={{ width: "16px" }} alt="" />
                </div>
                <div className='FacilityEvent_timings'>
                  <div className='card_details_text'>Business Hrs</div>
                  <div class="events-info-timing">Mon-Fri 10 am - 8 pm</div>
                  <div class="events-info-timing">Sat-Sun Closed</div>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent:'flex-start', alignItems:'baseline' }}>
                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                  <img src="/assets/icons/fee-icon.svg" style={{ width: "16px" }} alt="" />
                </div>
                <div className='FacilityEvent_fees'>

                  <div>FEES</div>
                  <div class="events-info-timing">0$ for members</div>
                  <div class="events-info-timing">50$/tax for non members</div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent:'flex-start', alignItems:'baseline' }}>
              <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                <img src="/assets/icons/fee-icon.svg" style={{ width: "16px" }} alt="" />
              </div>
              <div className='FaciltyEvent_booking_hrs'>
                <div>Booking Hrs</div>
                <div class="events-info-timing">4 Hrs/Booking</div>
              </div>
            </div>

            <Link to="/EventsFacilityBooking">
              <button className='facility_booking_btn'>Book Now</button>
            </Link>
          </div>



        </div>

      </div>
      {/*         
      </div> */}





    </div>
  )
}

export default EventsFacilityBooking