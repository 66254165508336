import { useEffect } from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function Footer() {

    useEffect(() => {
        // const script = document.createElement("script");
        // script.src = "assets/rev-slider.js";
        // script.defer = true;
        // document.body.appendChild(script);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

    }

    return (
        <>

            <div className="footer">
                <div className="footer-inner ">
                    <div className="logo-container">
                        {/* <div className="sia-logo"></div> */}
                    </div>
                    <div className="footer-inner-flex">
                        <div className="footer-logo-col">
                            <div className="title-container">
                                Singapore Indian Association
                            </div>
                            <div className="asso-desc-container">
                                The <strong>Singapore Indian Association</strong> was <br /> established in 1923 with the objective <br /> of promoting the social, physical, <br /> intellectual, cultural and the <br /> general welfare of its members.
                            </div>
                        </div>
                        <div className="quick-links-container">
                            <div className="quick-link-heading">Quick Links</div>

                            <div className="all-quick-links">
                                <div style={{marginRight:"20px"}}>
                                    <div className="quick-link-links">About Us</div>
                                    <Link to="/history"><div className="sub-quick-link-links">Who We Are</div></Link>
                                    <Link to="/management-committee"><div className="sub-quick-link-links">Mgmt. Committee</div></Link>
                                    <Link to="/upcoming-events"><div className="sub-quick-link-links">General Office</div></Link>
                                    <Link to="/contactUs"><div className="sub-quick-link-links">Constitution</div></Link>
                                    <Link to="/contactUs"><div className="sub-quick-link-links">Women Wing</div></Link>
                                </div>

                                <div style={{marginRight:"20px"}}>
                                    <div className="quick-link-links">Events</div>
                                    <Link to="/upcoming-events"><div className="sub-quick-link-links">Upcoming Events</div></Link>
                                    <Link to="/galleryFolder"><div className="sub-quick-link-links">Gallery - Past Events</div></Link>
                                </div>

                                <div style={{marginRight:"10px"}}>
                                    <div className="quick-link-links">Bookings</div>
                                    <Link to="/AllEventsFacilityBooking"><div className="sub-quick-link-links">Events Facility</div></Link>
                                    <Link to="/SportsFacility"><div className="sub-quick-link-links">Sports Facility</div></Link>
                                    <Link to="/SportsClub"><div className="sub-quick-link-links">Sports Club</div></Link>
                                </div>

                            </div>
                        </div>
                     
                        <div className="socials-container">
                            <div className="social-heading">Follow Us</div>
                            <div className="social-icons-container">
                                <a target={"_blank"} href='https://www.facebook.com/SingaporeIndianAssociation/' ><div className="fb-icon"></div></a>
                                <a target={"_blank"} href='https://instagram.com/singaporeindianassociation?igshid=YmMyMTA2M2Y=' ><div className="ig-icon"></div></a>
                            </div>
                        </div>
                    </div>

                    <div className="scrollToTop"><button onClick={() => scrollToTop()}><img src="images/arrow-up-solid.svg" /></button></div>
                    <div style={{ display: 'flex', gap: "15px", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <div class="footer-seperator">

                        </div>
                        <div class="header-below-seperator">
                            Powered by <a target={"_blank"} href='https://reddotinnovative.com/'>Red Dot Innovative </a> 2022

                        </div>
                    </div>

                    <div className="footer-inner-inner ">

                        <div className="footer-inner-flex-column">
                            <div className="footer-logo-col">
                                <div className="logo-container">
                                    {/* <div className="sia-logo"></div> */}
                                </div>
                                <div className="title-container">
                                    Singapore Indian Association
                                </div>
                                <div className="asso-desc-container">
                                    The <strong>Singapore Indian Association</strong> was <br /> established in 1923 with the objective <br /> of promoting the social, physical, <br /> intellectual, cultural and the <br /> general welfare of its members.
                                </div>
                            </div>
                            <div className="quick-link-heading">Quick Links</div>
                            <div className="second_container">
                                <div className="quick-links-container">
                                    <div className="quick-link-links">About Us</div>
                                    <Link to="/history"><div className="sub-quick-link-links">Who We Are</div></Link>
                                    <Link to="/galleryFolder"><div className="sub-quick-link-links">Mgmt. Committee</div></Link>
                                    <Link to="/upcoming-events"><div className="sub-quick-link-links">General Office</div></Link>
                                    <Link to="/contactUs"><div className="sub-quick-link-links">Constitution</div></Link>
                                    <Link to="/contactUs"><div className="sub-quick-link-links">Women Wing</div></Link>
                                </div>

                                <div className="bookings_follow_us_cont">
                                <div className="quick-links-container">
                                <div className="quick-link-links">Bookings</div>
                                    <Link to="/AllEventsFacilityBooking"><div className="sub-quick-link-links">Events Facility</div></Link>
                                    <Link to="/SportsFacility"><div className="sub-quick-link-links">Sports Facility</div></Link>
                                    <Link to="/SportsClub"><div className="sub-quick-link-links">Sports Club</div></Link>
                                </div>
                                <div className="quick-links-container">
                                <div className="quick-link-links">Events</div>
                                    <Link to="/upcoming-events"><div className="sub-quick-link-links">Upcoming Events</div></Link>
                                    <Link to="/galleryFolder"><div className="sub-quick-link-links">Gallery - Past Events</div></Link>
                                </div>
                              
                                </div>
                            </div>

                            
                            <div className="second_container">
                                 
                            <div className="follow_icons">
                            <div className="social-heading">Follow Us</div>
                                    <div className="social-icons-container">
                                        <a target={"_blank"} href='https://www.facebook.com/SingaporeIndianAssociation/' ><div className="fb-icon"></div></a>
                                        <a target={"_blank"} href='https://instagram.com/singaporeindianassociation?igshid=YmMyMTA2M2Y=' ><div className="ig-icon"></div></a>
                                    </div>

                            </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', gap: "15px", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <div class="footer-seperator">

                            </div>
                            <div class="header-below-seperator" style={{ textAlign: "center" }}>
                                Powered by <a target={"_blank"} href='https://reddotinnovative.com/'><div>Red Dot Innovative 2022 </div></a>

                            </div>
                        </div>

                    </div>

                </div>


                <div className="reach-out-container-holder">
                    <div className="reach-out-container">
                        <div className="reach-out-container-inner">
                            <div className="call-section">
                                <div className="call-icon"></div>
                                <div className="call-details">
                                    <div className="call-text">Give Us a Call</div>
                                    <div className="call-num"> (+65) 81 332 5695</div>
                                </div>
                            </div>
                            <div className="call-section">
                                <div className="mail-icon"></div>
                                <div className="call-details">
                                    <div className="call-text">Send Us a Message</div>
                                    <div className="call-num"> sgindian@singet.com.sg</div>
                                </div>
                            </div>

                            <div className="call-section">
                                <div className="address-icon"></div>
                                <div className="call-details">
                                    <div className="call-text">Our Address</div>
                                    <div className="call-num">69 BALESTIER ROAD <br /> Singapore 329677</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>


        </>
    );
}

export default Footer;
