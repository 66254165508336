import { Checkbox, Divider, Form, Radio, Select } from 'antd'
import React from 'react'
import MembershipFormSubmission from './MembershipFormSubmission'

function DeclarationForm({submitted}) {
    return (
        !submitted ? <div className="basicFormRow">
            <div className="besicFormInnerDiv">
                <p className="font-weight-bold">Membership Term</p>
                <Form.Item>
                    <Radio.Group defaultValue={1}>
                        <Radio value={1}>New Member</Radio>
                        <Radio value={2}>Renewal</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label='Term Years* ' name='title' style={{ width: "100%" }}>
                    <Select style={{ width: "100%" }}>
                        <Select.Option value="demo">Demo</Select.Option>
                    </Select>
                </Form.Item>
                <p className="font-weight-bold">Declaration</p>
                <p>Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.</p>
                <p>Date :</p>
                <Divider style={{ width: "40px" }} />
                <Form.Item name="remember" valuePropName="checked"
                >
                    <Checkbox>I understand that information i submitted is true.</Checkbox>
                </Form.Item>
                <Form.Item name="remember2" valuePropName="checked"
                >
                    <Checkbox>I agree to abide by SIA rules & regulations as present or as maybe changed from time to time.</Checkbox>
                </Form.Item>
            </div>
            <div>
            </div>
        </div> :
        <MembershipFormSubmission/>


    )
}

export default DeclarationForm