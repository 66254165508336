import React from 'react'
import './Login.css'
import {Form,Input,Checkbox,Button} from 'antd'
import { DownloadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useState } from 'react';



function Login() {
    const onChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
      };

      const [password, setPassword] = useState("");
      const handleSubmit =  () => {
        if(password==="sia1234"){
       
        localStorage.setItem('user', true)
        window.location.reload(false);
        }
        else{
 setPassword("")
            alert("Wrong password")
        }
      };
  return (
    <div className=""style={{background: "rgb(2,0,36)",background: "linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(53,53,161,1) 32%, rgba(0,212,255,1) 100%)",display:"flex",height:"100vh",width:"100vw",justifyContent:"center",alignItems:"center",textAlign:"center"}}>
        {/* <div className='login_container'>
            <div style={{textAlign:"center",marginBottom:"20px"}}>
                <img src="/img/logo.png"/>    
            </div>
            <div style={{ width: "100%", }}>
                   <div  style={{marginBottom:"20px"}}>
                    <label className='text-username'>Username</label>
                    <Input style={{marginTop:"10px"}} placeholder='NRIC/FIN Number' />
                   </div>
                   <div>
                    <label className='text-username'>Password</label>
                    <Input style={{marginTop:"10px"}}placeholder='4 Digit PIN' />
                   </div>
                   <div>
                  
                   </div>
                </div>
                <Checkbox style={{marginTop:"5px"}} onChange={onChange}>Remember Me</Checkbox>
                <div style={{textAlign:"center",marginTop:"30px",marginBottom:"50px"}}>
                Forgot your Login 4 Digit PIN?<br/>
<span style={{color:"#0068B3"}}>Reset your PIN</span>
                </div>
            <div>
            <Button type="primary" style={{width:"100%",marginTop:"17px",marginBottom:"12px"}}><Link to="/">Log In</Link></Button>
            </div>
            <div className='belowloginbutton'>By continuing, you agree to the August International Pte Ltd <span style={{color:"#0068B3"}}>Terms & Services</span></div>
        </div> */}
       
        <div style={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center"}}>
            <div >
                <img src="/images/logo.png"/>
            </div>
            <div className="coming_soon"style={{fontSize:"100px",color:"white",fontFamily:"Poppins,sans-serif"}}>Coming Soon</div>
            <div>
               <div className="coming_soon_info" style={{fontSize:"20px",color:"white",fontFamily:"Poppins,sans-serif"}}>
                Please insert password to access the website
               </div>
                <div className='credentials'>
                    <Input type="password"  value={password} onChange={({ target }) => setPassword(target.value)} placeholder='Password'/>
                    
                </div>
                <div  style={{textAlign:"center"}} className='login-btn-centered'>
                    <Button onClick={handleSubmit} type="submit" className='login-btn'>Sign In</Button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Login