import { Form, Input } from 'antd'
import React from 'react'

function ProposerForm() {
    return (
        <div>
            <p className="font-weight-bold">Proposer</p>
            <div className="basicFormRow">
                <div className="besicFormInnerDiv">
                    <Form.Item label='Name*' name='title' style={{ width: "100%" }}>
                        <Input />
                    </Form.Item>
                </div>
                <div className="besicFormInnerDiv">
                    <Form.Item label='Membership No*' name='title' style={{ width: "100%" }}>
                        <Input/>
                    </Form.Item>
                </div>
            </div>
            <div className="basicFormRow">
                <div className="besicFormInnerDiv">
                    <Form.Item label='Mobile Number*' name='title' style={{ width: "100%" }}>
                        <Input />
                    </Form.Item>
                </div>
                <div className="besicFormInnerDiv">
                    <Form.Item label='Home Contact Number**' name='title' style={{ width: "100%" }}>
                        <Input/>
                    </Form.Item>
                </div>
            </div>
            <p className="font-weight-bold">Secondar</p>
            <div className="basicFormRow">
                <div className="besicFormInnerDiv">
                    <Form.Item label='Name*' name='title' style={{ width: "100%" }}>
                        <Input />
                    </Form.Item>
                </div>
                <div className="besicFormInnerDiv">
                    <Form.Item label='Membership No*' name='title' style={{ width: "100%" }}>
                        <Input/>
                    </Form.Item>
                </div>
            </div>
            <div className="basicFormRow">
                <div className="besicFormInnerDiv">
                    <Form.Item label='Mobile Number*' name='title' style={{ width: "100%" }}>
                        <Input />
                    </Form.Item>
                </div>
                <div className="besicFormInnerDiv">
                    <Form.Item label='Home Contact Number**' name='title' style={{ width: "100%" }}>
                        <Input/>
                    </Form.Item>
                </div>
            </div>
        </div>
    )
}

export default ProposerForm