import React from 'react'
import './SportsClub.css';
import { useState } from 'react';
import { useEffect } from "react";

function SportsClub() {
    const [SportsClub, setSportsClub] = useState(true);
    var Sports = 1;
    useEffect(()=>{
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
    },[])
    return (
        <div className='marginTopFromNavbar'>


            {/* <div className="events_component_1">
                <div className="events_1">
                    <h1>SPORTS CLUB</h1>
                </div>
                <div className="events_2">HOME - Bookings - Sports Club - All Sports Club</div>
            </div> */}
              {/* <div className="header_new">
                    <div className="text-container">
                    <div className="text">SPORTS CLUB</div>
               <    div className="text2">HOME - Bookings - Sports Club - All Sports Club</div>
                  </div>
             </div> */}

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", margin: "auto" }}>

                <div className='intro-text-header'>Sports Club</div>
                <hr />
                <div className='intro-text'>Sports club booking involves reserving a particular facility or venue belonging to a sports club for a specific purpose, such as a training session or a match. The process may include selecting a date and time that is suitable for both the club and the individual or team, as well as arranging any necessary equipment or resources needed for the activity. Depending on the club's policies, it may also be necessary to negotiate pricing and obtain any required permits or insurance. Efficient sports club booking can ensure a smooth and enjoyable experience for all participants while supporting the goals and objectives of the club.</div>
                </div>



            <div className='Card_Events'>
                <div class="sports_club">
                    <button onClick={()=>setSportsClub(true)}>All Sports Club</button>
                    <button onClick={()=>setSportsClub(false)}>My Sports Club</button>
                </div>
                {SportsClub ==1?
                <div>
                    <div className="FacilityEvent_main">
                    <div className='FacilityEvent'>
                        <img src="/images/HockeyNew.jpg" />
                        <div style={{ padding: "24px" }}>
                            <div >
                            <div style={{display:"flex" , justifyContent:"space-between",alignItems:"center"}}>
                                <div className='SportsEvents'>Indoor Hall</div>
                                <div className='sports_availability'>10 Seats Available</div>
                            </div>
                            <div >
                                
                                <div className='FacilityEvent_desc'>Celebrate WITH FREINDS AND FAMILY</div>
                            </div>
                            </div>
                            <div className="FacilityEvent_info">
                                        <div className=''>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/briefcase.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FacilityEvent_timings'>
                                                    <div>Business Hrs</div>
                                                    <div class="events-info-timing">Mon-Fri 10 am - 8 pm</div>
                                                    <div class="events-info-timing">Sat-Sun Closed</div>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/fee-icon.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FaciltyEvent_booking_hrs'>
                                                    <div>Booking Hrs</div>
                                                    <div class="events-info-timing">4 Hrs/Booking</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=''>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/fee-icon.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FacilityEvent_fees'>

                                                    <div>FEES</div>
                                                    <div class="events-info-timing">0$ for members</div>
                                                    <div class="events-info-timing">50$/tax for non members</div>
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/pax.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FaciltyEvent_booking_hrs'>
                                                    <div>PAX</div>
                                                    <div class="events-info-timing">20 PAX Booking Done</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                            <button className='facilityEvent_book'>Book Now</button>
                        </div>



                    </div>

                    <div className='FacilityEvent'>
                    <img src="/images/HockeyNew.jpg" />
                        <div style={{ padding: "24px" }}>
                            <div >
                            <div style={{display:"flex" , justifyContent:"space-between",alignItems:"center"}}>
                                <div className='SportsEvents'>Indoor Hall</div>
                                <div className='sports_availability'>10 Seats Available</div>
                            </div>
                            <div >
                                
                                <div className='FacilityEvent_desc'>Celebrate WITH FREINDS AND FAMILY</div>
                            </div>
                            </div>
                            <div className="FacilityEvent_info">
                                        <div className=''>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/briefcase.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FacilityEvent_timings'>
                                                    <div>Business Hrs</div>
                                                    <div class="events-info-timing">Mon-Fri 10 am - 8 pm</div>
                                                    <div class="events-info-timing">Sat-Sun Closed</div>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/fee-icon.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FaciltyEvent_booking_hrs'>
                                                    <div>Booking Hrs</div>
                                                    <div class="events-info-timing">4 Hrs/Booking</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=''>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/fee-icon.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FacilityEvent_fees'>

                                                    <div>FEES</div>
                                                    <div class="events-info-timing">0$ for members</div>
                                                    <div class="events-info-timing">50$/tax for non members</div>
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/pax.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FaciltyEvent_booking_hrs'>
                                                    <div>PAX</div>
                                                    <div class="events-info-timing">20 PAX Booking Done</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                            <button className='facilityEvent_book'>Book Now</button>
                        </div>

                    </div>

                </div>

                <div className="FacilityEvent_main">
                    <div className='FacilityEvent'>
                    <img src="/images/HockeyNew.jpg" />
                        <div style={{ padding: "24px" }}>
                            <div >
                            <div style={{display:"flex" , justifyContent:"space-between",alignItems:"center"}}>
                                <div className='SportsEvents'>Indoor Hall</div>
                                <div className='sports_availability'>10 Seats Available</div>
                            </div>
                            <div >
                                
                                <div className='FacilityEvent_desc'>Celebrate WITH FREINDS AND FAMILY</div>
                            </div>
                            </div>
                            <div className="FacilityEvent_info">
                                        <div className=''>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/briefcase.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FacilityEvent_timings'>
                                                    <div>Business Hrs</div>
                                                    <div class="events-info-timing">Mon-Fri 10 am - 8 pm</div>
                                                    <div class="events-info-timing">Sat-Sun Closed</div>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/fee-icon.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FaciltyEvent_booking_hrs'>
                                                    <div>Booking Hrs</div>
                                                    <div class="events-info-timing">4 Hrs/Booking</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=''>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/fee-icon.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FacilityEvent_fees'>

                                                    <div>FEES</div>
                                                    <div class="events-info-timing">0$ for members</div>
                                                    <div class="events-info-timing">50$/tax for non members</div>
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/pax.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FaciltyEvent_booking_hrs'>
                                                    <div>PAX</div>
                                                    <div class="events-info-timing">20 PAX Booking Done</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                            <button className='facilityEvent_book'>Book Now</button>
                        </div>


                    </div>

                    <div className='FacilityEvent'>
                    <img src="/images/HockeyNew.jpg" />
                        <div style={{ padding: "24px" }}>
                            <div >
                            <div style={{display:"flex" , justifyContent:"space-between",alignItems:"center"}}>
                                <div className='SportsEvents'>Indoor Hall</div>
                                <div className='sports_availability'>10 Seats Available</div>
                            </div>
                            <div >
                                
                                <div className='FacilityEvent_desc'>Celebrate WITH FREINDS AND FAMILY</div>
                            </div>
                            </div>
                            <div className="FacilityEvent_info">
                                        <div className=''>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/briefcase.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FacilityEvent_timings'>
                                                    <div>Business Hrs</div>
                                                    <div class="events-info-timing">Mon-Fri 10 am - 8 pm</div>
                                                    <div class="events-info-timing">Sat-Sun Closed</div>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/fee-icon.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FaciltyEvent_booking_hrs'>
                                                    <div>Booking Hrs</div>
                                                    <div class="events-info-timing">4 Hrs/Booking</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=''>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/fee-icon.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FacilityEvent_fees'>

                                                    <div>FEES</div>
                                                    <div class="events-info-timing">0$ for members</div>
                                                    <div class="events-info-timing">50$/tax for non members</div>
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/pax.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FaciltyEvent_booking_hrs'>
                                                    <div>PAX</div>
                                                    <div class="events-info-timing">20 PAX Booking Done</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                            <button className='facilityEvent_book'>Book Now</button>
                        </div>

                    </div>

                </div>
                </div>
                
                :
                <div className="FacilityEvent_main">
                    <div className='FacilityEvent'>
                        <img src="/images/HockeyNew.jpg" />
                        <div style={{ padding: "24px" }}>
                            <div >
                            <div style={{display:"flex" , justifyContent:"space-between",alignItems:"center"}}>
                                <div className='SportsEvents'>Indoor Hall</div>
                                <div className='sports_availability'>10 Seats Available</div>
                            </div>
                            <div >
                                
                                <div className='FacilityEvent_desc'>Celebrate WITH FREINDS AND FAMILY</div>
                            </div>
                            </div>
                            <div className="FacilityEvent_info">
                                        <div className=''>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/briefcase.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FacilityEvent_timings'>
                                                    <div>Business Hrs</div>
                                                    <div class="events-info-timing">Mon-Fri 10 am - 8 pm</div>
                                                    <div class="events-info-timing">Sat-Sun Closed</div>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/fee-icon.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FaciltyEvent_booking_hrs'>
                                                    <div>Booking Hrs</div>
                                                    <div class="events-info-timing">4 Hrs/Booking</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=''>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/fee-icon.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FacilityEvent_fees'>

                                                    <div>FEES</div>
                                                    <div class="events-info-timing">0$ for members</div>
                                                    <div class="events-info-timing">50$/tax for non members</div>
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/pax.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FaciltyEvent_booking_hrs'>
                                                    <div>PAX</div>
                                                    <div class="events-info-timing">20 PAX Booking Done</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                            <button className='facilityEvent_book'>Book Now</button>
                        </div>



                    </div>

                    <div className='FacilityEvent'>
                    <img src="/images/HockeyNew.jpg" />
                        <div style={{ padding: "24px" }}>
                            <div >
                            <div style={{display:"flex" , justifyContent:"space-between",alignItems:"center"}}>
                                <div className='SportsEvents'>Indoor Hall</div>
                                <div className='sports_availability'>10 Seats Available</div>
                            </div>
                            <div >
                                
                                <div className='FacilityEvent_desc'>Celebrate WITH FREINDS AND FAMILY</div>
                            </div>
                            </div>
                            <div className="FacilityEvent_info">
                                        <div className=''>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/briefcase.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FacilityEvent_timings'>
                                                    <div>Business Hrs</div>
                                                    <div class="events-info-timing">Mon-Fri 10 am - 8 pm</div>
                                                    <div class="events-info-timing">Sat-Sun Closed</div>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/fee-icon.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FaciltyEvent_booking_hrs'>
                                                    <div>Booking Hrs</div>
                                                    <div class="events-info-timing">4 Hrs/Booking</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=''>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/fee-icon.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FacilityEvent_fees'>

                                                    <div>FEES</div>
                                                    <div class="events-info-timing">0$ for members</div>
                                                    <div class="events-info-timing">50$/tax for non members</div>
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: '5px' }} className="detail-icon-business-hrs">
                                                    <img src="/assets/icons/pax.svg" style={{ width: "16px" }} alt="" />
                                                </div>
                                                <div className='FaciltyEvent_booking_hrs'>
                                                    <div>PAX</div>
                                                    <div class="events-info-timing">20 PAX Booking Done</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                            <button className='facilityEvent_book'>Book Now</button>
                        </div>

                    </div>

                </div>

                }
                
            </div>
        </div>
    )
}

export default SportsClub