import { DatePicker, Divider, Form, Input, Select } from "antd";
import React from "react";
import { PlusOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { useState } from 'react';
import './BasicDetailsForm.css'

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

function BasicDetailsForm() {

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([
        // {
        //     uid: '-1',
        //     name: 'image.png',
        //     status: 'done',
        //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        // }
    ])

    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    return (
        <div>
            <div className="basicFormRow">
                <div className="besicFormInnerDiv">
                    <Form.Item label='Upload Photo' name='pic'>
                        <Upload

                            listType="picture-card"
                            fileList={fileList}
                            onPreview={handlePreview}
                            onChange={handleChange}
                        >
                            {fileList.length >= 8 ? null : uploadButton}
                        </Upload>
                    </Form.Item>
                </div>
                <div>

                </div>
            </div>
            <div className="basicFormRow">
                <div className="besicFormInnerDiv">
                    <Form.Item label='Title' name='title' style={{ width: "100%" }}>
                        <Select style={{ width: "100%" }}>
                            <Select.Option value="demo">Demo</Select.Option>
                        </Select>
                    </Form.Item>
                </div>
                <div className="besicFormInnerDiv">
                    <Form.Item label='Name as per NRIC/FIN*' name='title' style={{ width: "100%" }}>
                        <Input />
                    </Form.Item>
                </div>
            </div>
            <div className="basicFormRow">
                <div className="besicFormInnerDiv">
                    <Form.Item label='Name to be printed on card*' name='title' style={{ width: "100%" }}>
                        <Input />
                    </Form.Item>
                </div>
                <div className="besicFormInnerDiv">
                    <Form.Item label='Joined Date*' name='title' style={{ width: "100%" }}>
                        <DatePicker style={{ width: "100%" }} />
                    </Form.Item>
                </div>
            </div>
            <div className="basicFormRow">
                <div className="besicFormInnerDiv">
                    <Form.Item label='Date of Birth*' name='title' style={{ width: "100%" }}>
                        <DatePicker style={{ width: "100%" }} />

                    </Form.Item>
                </div>
                <div className="besicFormInnerDiv">
                    <Form.Item label='Race' name='title' style={{ width: "100%" }}>
                        <Input />
                    </Form.Item>
                </div>
            </div>
            <div className="basicFormRow">
                <div className="besicFormInnerDiv">
                    <Form.Item label='Nationality' name='title' style={{ width: "100%" }}>
                        <Select style={{ width: "100%" }}>
                            <Select.Option value="demo">Demo</Select.Option>
                        </Select>
                    </Form.Item>
                </div>
                <div className="besicFormInnerDiv">
                    <Form.Item label='NRIC/FIN No*' name='title' style={{ width: "100%" }}>
                        <Input />
                    </Form.Item>
                </div>
            </div>
            <div className="basicFormRow">
                <div className="besicFormInnerDiv">
                    <Form.Item label='Email' name='title' style={{ width: "100%" }}>
                        <Input />
                    </Form.Item>
                </div>
                <div className="besicFormInnerDiv">
                    <Form.Item label='Marital Status*' name='title' style={{ width: "100%" }}>
                        <Select style={{ width: "100%" }}>
                            <Select.Option value="demo">Demo</Select.Option>
                        </Select>
                    </Form.Item>
                </div>
            </div>
            <div className="basicFormRow">
                <div className="besicFormInnerDiv">
                    <Form.Item label='Mobile Number*' name='title' style={{ width: "100%" }}>
                        <Input />
                    </Form.Item>
                </div>
                <div className="besicFormInnerDiv">
                    <Form.Item label='Home Contact Number*' name='title' style={{ width: "100%" }}>
                        <Input />
                    </Form.Item>
                </div>
            </div>
            <div className="basicFormRow">
                <div className="besicFormInnerDiv">
                    <Form.Item label='Office Contact Number' name='title' style={{ width: "100%" }}>
                        <Input />
                    </Form.Item>
                </div>
                <div className="besicFormInnerDiv">
                    <Form.Item label='Occupation* ' name='title' style={{ width: "100%" }}>
                        <Select style={{ width: "100%" }}>
                            <Select.Option value="demo">Demo</Select.Option>
                        </Select>
                    </Form.Item>
                </div>
            </div>
            <div className="basicFormRow">
                <div className="besicFormInnerDiv">
                    <Form.Item label='Company Name*' name='title' style={{ width: "100%" }}>
                        <Select style={{ width: "100%" }}>
                            <Select.Option value="demo">Demo</Select.Option>
                        </Select>
                    </Form.Item>
                </div>
                <div>

                </div>
            </div>

            <Divider />
            <p className="font-weight-bold">Address</p>
            <div className="basicFormRow">
                <div className="besicFormInnerDiv">
                    <Form.Item label='Block No' name='title' style={{ width: "100%" }}>
                        <Input />
                    </Form.Item>
                </div>
                <div className="besicFormInnerDiv">
                    <Form.Item label='Street No' name='title' style={{ width: "100%" }}>
                        <Input />
                    </Form.Item>
                </div>
            </div>
            <div className="basicFormRow">
                <div className="besicFormInnerDiv">
                    <Form.Item label='Level No*' name='title' style={{ width: "100%" }}>
                        <Input />
                    </Form.Item>
                </div>
                <div className="besicFormInnerDiv">
                    <Form.Item label='Unit No*' name='title' style={{ width: "100%" }}>
                        <Input />
                    </Form.Item>
                </div>
            </div>
            <div className="basicFormRow">
                <div className="besicFormInnerDiv">
                    <Form.Item label='Postal Code*' name='title' style={{ width: "100%" }}>
                        <Input />
                    </Form.Item>
                </div>
                <div className="besicFormInnerDiv">
                    <Form.Item label='Country' name='title' style={{ width: "100%" }}>
                        <Input />
                    </Form.Item>
                </div>
            </div>

        </div>
    );
}

export default BasicDetailsForm;
