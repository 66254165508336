import React from "react";
import "./gallery.css";
import { Image } from "antd";
import { Col, Row } from "antd";
import { useEffect } from "react";
import { Link } from "react-router-dom";

function Gallery() {
  useEffect(()=>{
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
},[])
  return (
    <div className="marginTopFromNavbar">
      {/* <div className="events_component_1">
        <div className="events_1">
          <h1>GALLERY</h1>
        </div>
        <div className="events_2">HOME - Gallery</div>
      </div> */}

      {/* <div className="header_new">
          <div className="text-container">
            <div className="text">GALLERY</div>
            <div className="text2">Home - Gallery</div>
          </div>
      </div> */}

      <div className="gallery-container">
        <div className="gallery_main">
          <Row className="gallery-imgs-row gallery-imgs-row-galleryFolder" gutter={[16, 24]}>
          <Col sm={24} lg={12}>
            <Link to="/gallery/DiwaliCelebrationBazar">
                <div className="image-gallery-folder">
                  <img
                    className="small_img_folder"
                    src="/assets/images/DiwaliCelebrationBazar/953.JPG"
                  alt=""
                  />
                  <div className="image_Overlays">

                  </div>
                  <div className="text-on-image">
                    
                    <div className="text-title">
                    Diwali Celebration Bazar
                    </div>
                    {/* <div className="text-date">
                    July 31, 2022
                    </div> */}
                  </div>
                  </div>
                  </Link>
                </Col>
                <Col sm={24} lg={12}>
            <Link to="/gallery/DiwaliBazarHighTea">
                <div className="image-gallery-folder">
                  <img
                    className="small_img_folder"
                    src="/assets/images/DiwaliBazarHighTea/853.JPG"
                  alt=""
                  />
                    <div className="image_Overlays">

                  </div>
                  <div className="text-on-image">
                    <div className="text-title">
                    Diwali Bazar High Tea
                    </div>
                    {/* <div className="text-date">
                    July 31, 2022
                    </div> */}
                  </div>
                  </div>
                  </Link>
                </Col>
                <Col sm={24} lg={12}>
            <Link to="/gallery/IASportCarnival">
                <div className="image-gallery-folder">
                  <img
                    className="small_img_folder"
                    src="/assets/images/IASportCarnival/51.JPG"
                  alt=""
                  />
                    <div className="image_Overlays">

                    </div>
                  <div className="text-on-image">
                    <div className="text-title">
                    IA Sports Carnival
                    </div>
                    {/* <div className="text-date">
                    July 31, 2022
                    </div> */}
                  </div>
                  </div>
                  </Link>
                </Col>
                {/* <Col sm={24} lg={12}>
            <Link to="/gallery">
                <div className="image-gallery-folder">
                  <img
                    className="small_img_folder"
                    src="/assets/images/Rectangle 86.jpg"
                  alt=""
                  />
                  
                  <div className="text-on-image">
                    <div className="text-title">
                    SIA SPORTS DAY 2022
                    </div>
                    <div className="text-date">
                    July 31, 2022
                    </div>
                  </div>
                  </div>
                  </Link>
                </Col> */}
             
              
             
               
          </Row>
          
        </div>
      </div>

    </div>
  );
}

export default Gallery;
